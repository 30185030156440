/////////////////////////////
//  B A S E   L A Y E R S  //
/////////////////////////////

// Initialize the map with its core features

////////////////////////
//   I M P O R T S    //
////////////////////////

// O P E N   L A Y E R S
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import VectorImageLayer from "ol/layer/VectorImage";
import GeoJSON from "ol/format/GeoJSON";
import { getUid } from "ol/util";
import { bbox } from "ol/loadingstrategy";

// C U S T O M
import map from "./initMap";

// J S O N
import baseMapCountries from "../../data/basemap-vector/countries/countries.geojson";
import baseMapCities from "../../data/basemap-vector/cities/cities.geojson";
import baseMapCapitalCities from "../../data/basemap-vector/cities/capitals.geojson";
import uaOcId from "../../data/basemap-vector/ocid/opencellID.geojson";

//  BEGINS  // ==>

// start a timer
console.time("Basemap Load Time");

// country layer style
const countryStyle = new Style({
  stroke: new Stroke({
    color: "rgba(40, 40, 40, .2)",
    width: 3,
  }),
  text: new Text({
    font: "17px Calibri, sans-serif",
    fill: new Fill({
      color: "#fff",
    }),
    stroke: new Stroke({
      color: "#000",
      width: 3.5,
    }),
  }),
});

// city layer style
const cityStyle = new Style({
  text: new Text({
    font: "15px Calibri, sans-serif",
    fill: new Fill({
      color: "#000",
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3,
    }),
  }),
});

// basemap vector prototypes
const baseMapVectorLayers = {
  countries: {
    name: "Countries",
    className: "map-vector-basemaplayer",
    source: new VectorSource({
      format: new GeoJSON(),
      url: baseMapCountries,
    }),
    style: (feature) => {
      countryStyle.getText().setText(feature.get("Name"));
      return countryStyle;
    },
  },
  capitals: {
    name: "Capitals",
    className: "map-vector-basemaplayer",
    source: new VectorSource({
      format: new GeoJSON(),
      url: baseMapCapitalCities,
    }),
    style: (feature) => {
      cityStyle.getText().setText(feature.get("Name"));
      return cityStyle;
    },
  },
  cities: {
    name: "Cities",
    className: "map-vector-basemaplayer",
    source: new VectorSource({
      format: new GeoJSON(),
      url: baseMapCities,
    }),
    style: (feature) => {
      cityStyle.getText().setText(feature.get("Name"));
      return cityStyle;
    },
    declutter: true,
  },
  openCellID: {
    name: "OpenCelliD",
    className: "map-vector-basemaplayer",
    source: new VectorSource({
      format: new GeoJSON(),
      url: uaOcId,
    }),
    style: new Style({
      image: new CircleStyle({
        radius: 25,
        fill: new Fill({
          color: "#0096f0",
        }),
        stroke: new Stroke({
          color: "#00000000",
          width: 0,
        }),
      }),
    }),
    opacity: 0.45,
  },
};

// add the basemap layers to the map
Object.keys(baseMapVectorLayers).forEach((baseLayer) => {
  const baseLayerVector = new VectorImageLayer(baseMapVectorLayers[baseLayer]);
  baseLayerVector.set("group", "Basemap Vector");
  baseLayerVector.set("type", "Vector");
  baseLayerVector.set("Name", baseMapVectorLayers[baseLayer].name);
  map.addLayer(baseLayerVector);
});

/////////////////////////////////////
//  A D D   F E A T U R E   I D S  //
/////////////////////////////////////

// get only the map vector layers
const vectorLayers = map
  .getLayers()
  .getArray()
  .filter((layer) => layer.get("type") === "Vector");

// create a new async function waiting for the layer source to fully load
const loadSourcePromise = (layer) => {
  return new Promise((resolve, reject) => {
    const source = layer.getSource();
    source.set("Name", layer.get("Name"));
    console.log(`Info: Loading ${layer.get("Name")}...`);
    // at every change (each feature adding) check if they're done (=== "ready")
    const layerLoad = setInterval(() => {
      if (source.getState() === "ready") {
        clearInterval(layerLoad);
        resolve(source);
      }
    }, 100);
  });
};

// promise array to hold the promises
const loadSourcesPromises = [];

// iterage vector layers and append their load promise
vectorLayers.forEach((layer) =>
  loadSourcesPromises.push(loadSourcePromise(layer))
);

Promise.all(loadSourcesPromises).then((source) => {
  source.forEach((source) => {
    // add uid's to each feature
    source.getFeatures().forEach((f) => {
      f.setId(getUid(f));
      f.set("Layer", source.get("Name"));
      f.get("Name") || f.set("Name", getUid(f));
    });
  });
  console.info("Info: Basemap Layers Loaded");
  console.timeEnd("Basemap Load Time");
});
