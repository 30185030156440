// create an html snippet with a features details added
const featureItemTemplate = (feature, layer) => {
  const featureName = feature.get('Name');
  const layerName = layer.get('Name');
  const uID = feature.getId();
  const layerStyleToggle =
    ['Basemap Vector', 'Basemap Layer'].includes(layer.get('group')) || feature.get('Point Style') === 'Image';

  return `
    <div data-search="${featureName}" class="map-feature-list-feature-container">
      <div class="map-feauture-list-feature-item" data-layer="${layerName}" data-featureuid="${uID}" data-featurename="${featureName}">
        <span>${featureName}</span>
      </div>
      <div class="map-feature-list-feature-tools">
        <div data-tool="zoom" data-layer="${layerName}" data-featureuid="${uID}" data-featurename="${featureName}" class="map-feature-list-feature-tool-zoom">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 -2.5 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="map-feature-list-feature-tool"><path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm6.32-1.094l3.58 3.58a1 1 0 1 1-1.415 1.413l-3.58-3.58a8 8 0 1 1 1.414-1.414z"></path></svg>
        </div>
        <div data-tool="move" data-layer="${layerName}" data-featureuid="${uID}" data-featurename="${featureName}"class="map-feature-list-feature-tool-move">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -1 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="map-feature-list-feature-tool"><path d="M18.799 9.957l-1.121-1.121a1 1 0 1 1 1.414-1.415l2.828 2.829a1 1 0 0 1 0 1.414l-2.828 2.828a1 1 0 0 1-1.414-1.414l1.121-1.121h-6.485v6.485l1.121-1.121a1 1 0 0 1 1.414 1.414l-2.828 2.829a1 1 0 0 1-1.414 0l-2.829-2.829a1 1 0 0 1 1.414-1.414l1.122 1.121v-6.485H3.828l1.122 1.121a1 1 0 0 1-1.414 1.414L.707 11.664a1 1 0 0 1 0-1.414L3.536 7.42A1 1 0 0 1 4.95 8.836l-1.122 1.12h6.486V3.473L9.192 4.593a1 1 0 0 1-1.414-1.414L10.607.35a1 1 0 0 1 1.414 0l2.828 2.829a1 1 0 1 1-1.414 1.414l-1.121-1.121v6.485h6.485z"></path></svg>
        </div>
        ${
          layerStyleToggle
            ? ''
            : `
        <div data-tool="style" data-layer="${layerName}" data-featureuid="${uID}" data-featurename="${featureName}" class="map-feature-list-feature-tool-style">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 -3 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="map-feature-list-feature-tool"><path d="M5.648 12.276l-1.65 1.1-.415 1.68 1.665-.42 1.104-1.656-.704-.704zM7.1 10.899l.627.627.091-.032c.937-.334 1.88-1.019 2.824-2.089 1.139-1.29 3.061-3.587 5.757-6.879a.211.211 0 0 0-.297-.297c-3.286 2.693-5.583 4.616-6.881 5.758-1.076.946-1.76 1.888-2.088 2.819l-.033.093zm-.615 5.486L.843 17.814l1.4-5.671 3.004-2.004C5.7 8.863 6.583 7.645 7.9 6.486c1.32-1.162 3.632-3.097 6.936-5.804a2.21 2.21 0 0 1 3.111 3.112c-2.71 3.309-4.645 5.62-5.804 6.934-1.156 1.31-2.373 2.193-3.652 2.65l-2.005 3.007z"></path></svg>
        </div>
        `
        }
        <div data-tool="delete" data-layer="${layerName}" data-featureuid="${uID}" data-featurename="${featureName}" class="map-feature-list-feature-tool-delete">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -1 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="map-feature-list-feature-tool"><path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path><path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path></svg>
        </div>
      </div>
    </div>
  `;
};

export default featureItemTemplate;
