//////////////////////////
//  S H O R T   C U T S //
//////////////////////////

// Add shortcuts to the map

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N   L A Y E R S
import { fromLonLat, toLonLat } from "ol/proj";
import {
  Select,
  Translate,
  defaults as defaultInteractions,
} from "ol/interaction";
import Collection from "ol/Collection";

// C U S T O M
import map from "./initMap";
import toast from "./toast";
import { coordToMGRS } from "./utils";
import { shiftKeyOnly } from "ol/events/condition";

// BEGINS // -->

// G L O B A L   S H O R T C U T S

// on click
map.on("click", (event) => {
  if (event.originalEvent.ctrlKey && !event.originalEvent.shiftKey) {
    // I F   C T R L   K E Y
    // C O P Y   M O U S E   C O O R D
    const coords = coordToMGRS(event.coordinate);
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = coords;
    tempTextArea.style.opacity = 0;
    document.body.append(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    toast(`Copied MGRS grid: ${coords} to clipboard.`);
    tempTextArea.remove();
  } else if (event.originalEvent.shiftKey) {
    // I F   S H I F T   K E Y
    // M O V E   F E A T U R E   A T   M O U S E

    const feature = map.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 50,
    })[0];

    if (feature) {
      setTimeout(() => {
        moveFeature(feature);
      }, 500);
    }
  }
});

const moveFeature = (feature) => {
  setTimeout(() => {
    // throw the feature in a collection
    const collection = new Collection();
    collection.push(feature);

    // 'select' it
    const select = new Select({
      features: collection,
    });

    // 'select' our feature
    select.dispatchEvent({
      type: "select",
      selected: [feature],
      deselected: [],
    });
    // create translate function on our selected feature
    const translate = new Translate({
      features: select.getFeatures(),
    });

    // add the interaction to the map
    map.addInteraction(select);
    map.addInteraction(translate);
    map.on("click", () => {
      map.removeInteraction(select);
      map.removeInteraction(translate);
    });
    toast(`Moving feature ${feature.get("Name")}`);
  }, 100);
};
