//////////////////////////
//  C R O S S H A I R  //
/////////////////////////

// Add a map center crosshair with a coordinate display

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N L A Y E R S
import { Map, Overlay, View } from "ol";
import * as olEvents from "ol/events";

// C U S T O M
import "../main";
import map from "./initMap";
import toolBar from "./mapToolbar";
import toast from "./toast";
import { coordToMGRS } from "./utils";

// BEGINS // -->

const coordDisplayContainer = document.querySelector("#map-coord-container");
const coordDisplayCursor = document.querySelector("#map-coord-display-MGRS");
const coordDisplayCenter = document.querySelector("#map-coord-display-center");
const centerCoordContainer = document.querySelector(
  "#map-coord-container div:nth-child(2)"
);
const mouseCoordContainer = document.querySelector(
  "#map-coord-container div:nth-child(1)"
);

const centerCrosshairContainer = document.querySelector(
  "#map-center-crosshair"
);

const mapCenterCrosshair = new Overlay({
  element: centerCrosshairContainer,
  className: "overlay-container",
  zIndex: 3,
});
mapCenterCrosshair.setPosition(map.getView().getCenter());
map.addOverlay(mapCenterCrosshair);

// center the centermap crosshair
mapCenterCrosshair.setPosition(map.getView().getCenter());
map.addOverlay(mapCenterCrosshair);

map.on("precompose", () => {
  // center the centermap crosshair
  mapCenterCrosshair.setPosition(map.getView().getCenter());
});

const checkBothCoordDisplays = () => {
  coordDisplayContainer.style.display =
    mouseCoordContainer.style.display === "none" &&
    centerCrosshairContainer.style.display === "none"
      ? "none"
      : "";
};

// control to toggle the mouse coordinate window
const cntButton = document.createElement("div");
cntButton.dataset.tooltip = "Toggle the mouse cursor MGRS coordinate display.";
cntButton.innerHTML = "MCH";
const cntContainer = document.createElement("div");
cntContainer.className = "ol-unselectable ol-control";
cntContainer.appendChild(cntButton);
cntButton.addEventListener("click", () => {
  const shown = mouseCoordContainer.style.display;
  if (shown === "none") {
    toast("Enabled mouse crosshair display");
    mouseCoordContainer.style.display = "";
  } else {
    toast("Disabled mouse crosshair display");
    mouseCoordContainer.style.display = "none";
  }
  checkBothCoordDisplays();
});
toolBar.appendChild(cntContainer);

// control to toggle the center coordinate on the map
const chButton = document.createElement("div");
chButton.setAttribute("data-tooltip", "Toggle the Center Crosshair widget");
chButton.innerHTML = "CCH";
const chContainer = document.createElement("div");
chContainer.className = "ol-unselectable ol-control";
chContainer.appendChild(chButton);

chButton.addEventListener("click", () => {
  const shown = centerCrosshairContainer.style.display;
  if (shown === "none") {
    toast("Enabled center crosshair and display");
    centerCrosshairContainer.style.display = "";
    centerCoordContainer.style.display = "";
  } else {
    toast("Disabled center crosshair and display");
    centerCrosshairContainer.style.display = "none";
    centerCoordContainer.style.display = "none";
  }
  checkBothCoordDisplays();
});
toolBar.appendChild(chContainer);

///////////////////////////////////////////
//  C O O R D I N A T E   D I S P L A Y  //
///////////////////////////////////////////

// when the pointer moves, get the inner lat lon, cast it as mgrs and display it
map.on("pointermove", (event) => {
  try {
    coordDisplayCursor.innerHTML = coordToMGRS(event.coordinate);
    coordDisplayCenter.innerHTML = coordToMGRS(map.getView().getCenter());
  } catch (RangeError) {
    console.warn("Warn: Mouse cursor out of expected range");
  }
});

/* this block is to test the crosshair is centered
const mapCenterCrosshairTest = new Feature({
  geometry: new Point(map.getView().getCenter()),
});
const mapCenterMarkerLayerTest = new VectorLayer({
  source: new VectorSource({
    features: [mapCenterCrosshairTest],
  }),
  style: new Style({
    text: new Text({
      text: "+",
      scale: 5,
      fill: new Fill({
        color: "blue",
      }),
    }),
  }),
});
map.addLayer(mapCenterMarkerLayerTest);
*/
