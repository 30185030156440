/////////////////////////
//  M A P   P O P U P  //
/////////////////////////

// create a popup when clicking a geojson element

//////////////////////
//  I M P O R T S   //
//////////////////////`

// M G R S
import Mgrs, { LatLon } from "geodesy/mgrs.js";

// P A G E S
import nspSections from "./nsp/nspSections";

// S E L E C T

// C U S T O M
import toast from "./toast";
import { mcm } from "./mapMcm";
import layerControllerRefresh from "./mapLayerControl";
import ms from "milsymbol";
import { filterLayerByName, coordToMGRS } from "./utils";

// O P E N   L A Y E R S
import VectorLayer from "ol/layer/Vector";
import Overlay from "ol/Overlay";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import Style from "ol/style/Style";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Icon from "ol/style/Icon";
import { getUid } from "ol/util";
import map from "./initMap";
import { click } from "ol/events/condition";

// BEGINS // -->

const mapPopupContainer = document.querySelector("#map-popup-container");

const mapPopup = new Overlay({
  element: mapPopupContainer,
  autoPan: true,
  zIndex: 1,
});

map.addOverlay(mapPopup);

map.on("click", (evt) => {
  const feature = map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
    return feature;
  });
  if (feature && feature.get("Layer") === "Open Cell ID") {
    const geometry = feature.getGeometry();
    const coord = geometry.getCoordinates();
    const name = feature.get("Name");
    const cell = feature.get("cell");
    const radio = feature.get("radio");
    const range = feature.get("range");
    const created = new Date(parseInt(feature.get("created") + "000"));
    const createdLbl = new Intl.DateTimeFormat("en-US").format(created);
    const updated = new Date(parseInt(feature.get("updated") + "000"));
    const updatedLbl = new Intl.DateTimeFormat("en-US").format(updated);

    let content = `
      <div>Tower Number: ${name}</div>
      <div>Cell Number: ${cell}</div>
      <div>Radio Type: ${radio}</div>
      <div>Range (m): ${range}</div>
      <div>Created: ${createdLbl}</div>
      <div>Updated: ${updatedLbl}</div>
      <button id="map-popup-close">Close</button>
    `;

    mapPopupContainer.innerHTML = content;
    mapPopup.setPosition(coord);
    const close = document.querySelector("#map-popup-close");
    close.addEventListener("click", () => {
      mapPopup.setPosition(undefined);
    });
  }
});
