// C U S T O M
import map from "../initMap";
import { blurBackground } from "../utils";

// O P E N   L A Y E R S
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";

// C O L O R   P I C K E R
import Picker from "vanilla-picker";

const featureStyler = (feature) => {
  console.log("Info: ", feature);

  // the styler div element
  const featureStyleEditContainer = document.querySelector(
    "#map-feature-settings-container"
  );
  // ... and clear it
  const previousStyle = feature.getStyle();

  // capture the previous feature style for resetting
  const featureGeometry = feature.get("Geometry Type");

  // clear the previous style menu
  featureStyleEditContainer.innerHTML = "";
  // zoom to the feature
  map.getView().fit(feature.getGeometry().getExtent(), {
    size: map.getSize(),
    padding: [250, 250, 250, 250],
    maxZoom: 16,
  });

  // H T M L   F O R   S T Y L E R

  const styleTemplates = {
    fill: `
      <!-- Fill Color -->
      <div class="style-category">
        <div class="style-group">
          <span class="style-label">Fill Color</span>
          <div
            style="background-color: ${feature.get("Fill Color")}"
            class="color-picker"
            id="styleFillColor"
            data-color="${feature.get("Fill Color")}"
          ></div>
        </div>
      </div>
    `.trim(),
    stroke: `
      <!-- Stroke Color -->
      <div class="style-category">
        <div class="style-group">
          <span class="style-label">Outline Color</span>
          <div class="color-picker"
            style="background-color: ${feature.get("Stroke Color")}"
            id="styleStrokeColor"
            data-color="${feature.get("Stroke Color")}"
          ></div>
        </div>
        <!-- Stroke Width -->
        <div class="style-group stroke-width">
          <span class="style-label">Outline Width</span>
          <div>
            <input
              class="style-setting-number-range"
              id="styleStrokeWidthSlider"
              oninput="styleStrokeWidthInput.value = styleStrokeWidthSlider.value"
              type="range"
              min="0"
              max="20"
              value="${parseFloat(feature.get("Stroke Width"))}"
              step=".1"
            />
            <input 
              class="style-setting-number-text"
              id="styleStrokeWidthInput"
              oninput="styleStrokeWidthSlider.value = styleStrokeWidthInput.value"
              type="number"
              min="0"
              max="20"
              value="${parseFloat(feature.get("Stroke Width"))}"
              step=".1"
            />
          </div>
        </div>
      </div>
    `.trim(),
    text: `
      <!-- Text Text -->
      <div class="style-category">
        <div class="style-group style-text-label">
          <textarea
            id="styleText"
          >${feature.get("Name").trim()}</textarea>
          <span class="style-label">Label</span>
        </div>
        <!-- Text Color -->
        <div class="style-group">
          <span class="style-label">Text Color</span>
          <div
            style="background-color: ${feature.get("Text Color")}"
            class="color-picker"
            id="styleTextFillColor"
            data-color="${feature.get("Text Color")}"
          ></div>
        </div>
        <!-- Text Stroke Color -->
        <div class="style-group">
          <span class="style-label">Text Halo Color</span>
          <div
            style="background-color: ${feature.get("Text Stroke Color")}"
            class="color-picker"
            id="styleTextStrokeColor"
            data-color="${feature.get("Text Stroke Color")}"
          ></div>
        </div>
        <!-- Text Scale -->
        <div class="style-group stroke-width">
          <span class="style-label">Text Scale</span>
          <div>
            <input
              class="style-setting-number-range"
              id="sstsws"
              oninput="styleTextScale.value = sstsws.value"
              type="range"
              min="1"
              max="5"
              value="1"
              step=".1"
            />
            <input 
              class="style-setting-number-text"
              id="styleTextScale"
              oninput="sstsws.value = styleTextScale.value"
              type="number"
              min="1"
              max="5"
              value="1"
              step=".1"
            />
          </div>
        </div>
        <!-- Text Overflow -->
        <div class="style-group">
          <span class="style-label">Text Overflow</span>
          <input type="checkbox" id="styleTextOverflow">
        </div>
        <!-- Text Placement -->
        <div class="style-group">
          <span class="style-label">Text Placement</span>
          <div>
            <select id="styleTextPlacement">
              <option value="line">Line</option>
              <option value="point">Point</option>
            </select>
          </div>
        </div>
      </div>
    `.trim(),
    circle: `
      <!-- Circle Radius -->
      <div class="style-category">
        <div class="style-group stroke-width">
          <span class="style-label">Radius</span>
          <div>
            <input
              class="style-setting-number-range"
              id="ssrws"
              oninput="ssrwt.value = ssrws.value"
              type="range"
              min="0"
              max="50"
              value="${feature.get("Radius")}"
              step="1"
            />
            <input 
              class="style-setting-number-text"
              id="ssrwt"
              oninput="ssrws.value = ssrwt.value"
              type="number"
              min="0"
              max="50"
              value="${feature.get("Radius")}"
              step="1"
            />
          </div>
        </div>
      </div>
    `.trim(),
  };

  const styleTemplate = `
      <div class="style-groups">
        ${
          featureGeometry === "Polygon"
            ? styleTemplates.fill + styleTemplates.stroke + styleTemplates.text
            : ""
        }
        ${
          featureGeometry === "Line"
            ? styleTemplates.stroke + styleTemplates.text
            : ""
        }
        ${
          featureGeometry === "Point"
            ? styleTemplates.fill +
              styleTemplates.circle +
              styleTemplates.stroke +
              styleTemplates.text
            : ""
        }
        <div class="style-category">
          <div class="style-group">
            <button id="reset-style-selector">Reset</button>
            <button id="close-style-selector">Close</button>
            </div>
        </div>
      </div>`.trim();

  // add the html block to the div element
  featureStyleEditContainer.insertAdjacentHTML("beforeend", styleTemplate);

  // A P P L Y I N G   T H E   S T Y L E

  const applyStyle = () => {
    let fill;
    let stroke;
    let text;
    let circle;
    let fillStyle;
    let strokeStyle;
    let textStyle;
    let circleStyle;

    if (featureGeometry === ("Polygon" || "Point")) {
      fill = stroke = text = true;
    }
    if (featureGeometry === "Line") {
      stroke = text = true;
    }
    if (featureGeometry === "Point") {
      circle = true;
    }

    if (fill) {
      // F I L L
      // style fill color
      const styleFillColor =
        featureStyleEditContainer.querySelector("#styleFillColor").style
          .backgroundColor;

      fillStyle = new Fill({ color: styleFillColor });
    }

    if (stroke) {
      // S T R O K E
      // style outline color
      const styleStrokeColor =
        featureStyleEditContainer.querySelector("#styleStrokeColor").style
          .backgroundColor;
      // style outline width
      const styleStrokeWidthInput = featureStyleEditContainer.querySelector(
        "#styleStrokeWidthInput"
      ).value;

      strokeStyle = new Stroke({
        color: styleStrokeColor,
        width: styleStrokeWidthInput,
      });
    }

    if (text) {
      // T E X T
      // style text color
      const styleTextFillColor = featureStyleEditContainer.querySelector(
        "#styleTextFillColor"
      ).style.backgroundColor;
      // style text text
      const styleText =
        featureStyleEditContainer.querySelector("#styleText").value;
      // style text stroke color
      const styleTextStrokeColor = featureStyleEditContainer.querySelector(
        "#styleTextStrokeColor"
      ).style.backgroundColor;
      // style text scale
      const styleTextScale =
        featureStyleEditContainer.querySelector("#styleTextScale").value;
      // style text overflow
      const styleTextOverflow =
        featureStyleEditContainer.querySelector("#styleTextOverflow").checked;
      // style text placement
      const styleTextPlacement = featureStyleEditContainer.querySelector(
        "#styleTextPlacement"
      ).value;

      textStyle = new Text({
        text: styleText,
        font: "14px Calibri,sans-serif",
        scale: styleTextScale.toString(),
        overflow: styleTextOverflow,
        placement: styleTextPlacement,
        fill: new Fill({
          color: styleTextFillColor,
        }),
        stroke: new Stroke({
          color: styleTextStrokeColor,
          width: 2.5,
        }),
      });
    }

    if (circle) {
      // style radius
      const ssrwtV = featureStyleEditContainer.querySelector("#ssrwt").value;
      circleStyle = new Style({
        image: new CircleStyle({
          fill: fillStyle,
          stroke: strokeStyle,
          radius: ssrwtV,
        }),
        text: textStyle,
      });

      feature.setStyle(circleStyle);
    } else {
      // create a new style
      // and for each possible style type, apply it
      const newStyle = new Style();
      fill && newStyle.setFill(fillStyle);
      stroke && newStyle.setStroke(strokeStyle);
      text && newStyle.setText(textStyle);

      // finally apply the style
      feature.setStyle(newStyle);
    }
  };

  // find the color-picker divs
  const colorPickers =
    featureStyleEditContainer.querySelectorAll(".color-picker");

  if (colorPickers.length) {
    // init the color-pickers
    colorPickers.forEach((picker) => {
      new Picker({
        parent: picker,
        color: picker.dataset.color,
        editor: true,
        popup: "bottom",
        defaultColor: picker.dataset.color,
        onChange: function (color) {
          picker.style.backgroundColor = color.rgbaString;
          applyStyle();
        },
      });
    });
  }

  // on any input change the color
  const inputs = featureStyleEditContainer.querySelectorAll(
    "input, .color-picker, select, textarea"
  );
  inputs.forEach((input) => {
    input.addEventListener("input", () => {
      applyStyle();
    });
  });

  // blur the background
  blurBackground();

  // buttons
  const close = featureStyleEditContainer.querySelector(
    "#close-style-selector"
  );

  // shut'r down
  close.addEventListener("click", () => {
    // unblur other tools and close the editor
    blurBackground();
    featureStyleEditContainer.style.display = "";
  });

  const reset = featureStyleEditContainer.querySelector(
    "#reset-style-selector"
  );

  // apply the old style
  reset.addEventListener("click", () => {
    feature.setStyle(previousStyle);
  });

  // finally show the style menu
  featureStyleEditContainer.style.display = "flex";
};

export default featureStyler;
