/////////////////
//  U T I L S  //
/////////////////

// Misc utility functions and helpers

//////////////////////
//  I M P O R T S   //
//////////////////////

// C U S T O M
import map from "./initMap";

// O P E N   L A Y E R S
import { fromLonLat, toLonLat } from "ol/proj";
import { getUid } from "ol/util";

// M G R S
import Mgrs, { LatLon } from "geodesy/mgrs.js";

// BEGINS // -->

const createUID = () => {
  let dt = new Date().getTime();

  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );

  return uuid;
};

const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const resultOlString = `rgb(${[
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ].toString()})`;
  return resultOlString;
};

const mapGroupToID = (layer) => {
  // pass string return id'd string
  return `map-${layer.replace(" ", "-").toLowerCase()}`;
};

// blur other tools to ease editing
const blurBackground = () => {
  document.querySelector("#map-layer-list-features").classList.toggle("blur");
  document.querySelector("#map-layer-control").classList.toggle("blur");
  document.querySelector("#map-overview-container").classList.toggle("blur");
};

// return a map layer by NAME, not ID
const filterLayerByName = (layerName) => {
  const mapLayers = map.getLayers().getArray().slice();
  const layer = mapLayers.find((layer) => {
    return layer.get("Name") === layerName;
  });
  return layer;
};

// return a map layer by ID, not NAME
const filterLayerByID = (layerID) => {
  const mapLayers = map.getLayers().getArray().slice();
  const layer = mapLayers.find((layer) => {
    return getUid(layer) === layerID;
  });
  return layer;
};

// return MGRS coords from lat long
const coordToMGRS = (coord) => {
  const lonlat = toLonLat(coord);
  const lon = lonlat[0];
  const lat = lonlat[1];
  if (parseFloat(lat) < -80) {
    return "Out of MGRS Range";
  } else {
    const latlon = new LatLon(lat, lon);
    const utmCoord = latlon.toUtm();
    const mgrsCoord = utmCoord.toMgrs();
    return mgrsCoord.toString(10);
  }
};

const getDTG = (getSeconds = false) => {
  const now = new Date();
  const dd = now.getDate().toString().padStart(2, "0");
  const hh = now.getHours().toString().padStart(2, "0");
  const mm = now.getMinutes().toString().padStart(2, "0");
  const ss = now.getSeconds().toString().padStart(2, "0");
  const mon = now.toLocaleString("en-US", { month: "short" }).toUpperCase();
  const yy = now.getFullYear().toString().substr(2, 2);
  return `${dd}${hh}${mm}${getSeconds && ss}Z${mon}${yy}`;
};

// H I D E   O V E R L A Y S
// find all elements with class 'geo-overly' and hide them
// global variable visibility state tracker
let elementVisbility = {};
const hideOverlays = (hide) => {
  // this can be refactored by adding a class "overlay" to each overlay container
  const elements = document.querySelectorAll(".geo-overlay");
  elements.forEach((element) => {
    const id = element.id;
    const display = element.style.display;
    // store their previous 'state'
    hide && (elementVisbility[id] = display === "" ? "" : display);
    // for each, if it's not hidden hide it or restore its old display style
    element.style.display = hide ? "none" : elementVisbility[id];
  });
};

export {
  createUID,
  hexToRgb,
  mapGroupToID,
  blurBackground,
  filterLayerByName,
  coordToMGRS,
  getDTG,
  hideOverlays,
};
