///////////////////////////////////////
//  L O C A T I O N   D I A G R A M  //
///////////////////////////////////////

// Add functional map context menu on right-click

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N   L A Y E R S
import { Feature, Map, Overlay, View } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";

// C U S T O M
import map from "./initMap";
import toast from "./toast";
import toolBar from "./mapToolbar";
import placeMilPoint from "./mapNpp";

// M G R S
import Mgrs, { LatLon } from "geodesy/mgrs.js";

// BEGINS // -->

// mcm => map context menu
const mcm = document.querySelector("#map-mcm-menu");
const mapFrame = document.querySelector("#map");

// redraw on each load
const mcmRedraw = () => {
  // clear it's contents
  mcm.innerHTML = "";

  // menu items
  // element-id : {label-for-button, function-to-call}
  const mcmItems = {
    "get-coordinate": {
      label: "Copy MGRS Coordinate",
      callback: getMouseCoords,
    },
    "pan-to-MGRS": {
      label: "Pan to MGRS Coordinate",
      callback: panToMGRS,
    },
    "search-location": {
      label: "Search for a Location",
      callback: searchName,
    },
    "place-point": {
      label: "Place a Point",
      callback: placeMilPoint,
    },
  };

  // create the menu
  Object.keys(mcmItems).forEach((menuItem) => {
    // create menu item container
    const item = document.createElement("div");
    item.id = "map-mcm-" + menuItem;
    item.classList.add("map-mcm-item");
    item.innerHTML = mcmItems[menuItem].label;
    item.addEventListener("click", () => {
      mcmItems[menuItem].callback();
    });
    mcm.append(item);
  });
};

////////////////////////////////
//  M C M   F U N C T I O N S //
////////////////////////////////

// create an input
const initMcmInput = (placeholder, label) => {
  let mcmInput = document.querySelector("#map-mcm-input");
  let mcmSubmit = document.querySelector("#map-mcm-input-btn");
  // remove the input if it exists
  mcmInput && (mcmInput.remove(), mcmSubmit.remove());
  // create it eitherway
  mcmInput = document.createElement("input");
  // pan button
  mcmSubmit = document.createElement("button");
  mcmSubmit.id = "map-mcm-input-btn";
  mcmSubmit.innerHTML = label;
  mcmInput.id = "map-mcm-input";
  mcmInput.placeholder = placeholder;
  mcmInput.setAttribute("autocomplete", "off");
  mcm.prepend(mcmSubmit);
  mcm.prepend(mcmInput);

  mcmInput.addEventListener("keyup", (event) => {
    if (event.key !== "Enter") return;
    mcmSubmit.click();
  });
  mcmInput.focus();
};

////////////////////////////////////
//  C O P Y   M G R S    G R I D  //
////////////////////////////////////

// get the current mouse MGRS coords
const getMouseCoords = () => {
  let coords = document.querySelector("#map-coord-display-MGRS").innerText;
  // create a temporary textarea to copy coordinates from
  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = coords;
  tempTextArea.style.opacity = 0;
  document.body.append(tempTextArea);
  tempTextArea.select();
  document.execCommand("copy");
  toast(`Copied MGRS grid: ${coords} to clipboard.`);
  tempTextArea.remove();
};

///////////////////////
//  M G R S   P A N  //
///////////////////////

const panToMGRS = () => {
  initMcmInput("MGRS...", "Pan");

  let mcmInput = document.querySelector("#map-mcm-input");
  let mcmSubmit = document.querySelector("#map-mcm-input-btn");
  mcmSubmit.addEventListener("click", () => {
    const gridEntered = new Promise((resolve, reject) => {
      if (mcmInput.value) {
        resolve();
      }
    });

    gridEntered.then(() => {
      mcm.classList.toggle("open");
      mcmRedraw();

      // convert the grid to lat long
      const mgrs = Mgrs.parse(mcmInput.value);
      toast(`Panning to MGRS: ${mgrs}`);
      const latlon = mgrs.toUtm().toLatLon().toString("n").split(",").reverse();
      console.log("Info: Grid converted to: " + latlon);
      const location = fromLonLat(latlon);
      const view = map.getView();
      const duration = 1000;
      const zoom = view.getZoom();
      let parts = 2;
      let called = false;
      function callback(complete) {
        --parts;
        if (called) {
          return;
        }
        if (parts === 0 || !complete) {
          called = true;
        }
      }
      view.animate(
        {
          center: location,
          duration: duration,
        },
        callback
      );
      view.animate(
        {
          zoom: zoom - 2,
          duration: duration / 2,
        },
        {
          zoom,
          duration: duration / 2,
        },
        callback
      );
    });
  });
};

///////////////////////////////
//  S E A R C H   F O R   X  //
///////////////////////////////

const searchName = () => {
  initMcmInput("Search...", "Search");
  let mcmInput = document.querySelector("#map-mcm-input");
  let mcmSubmit = document.querySelector("#map-mcm-input-btn");
  mcmSubmit.addEventListener("click", () => {
    const searchEntered = new Promise((resolve, reject) => {
      if (mcmInput.value) {
        resolve();
      }
    });
    searchEntered.then(() => {
      toast(`Searching for: ${mcmInput.value}`);
      mcm.classList.toggle("open");
      mcmRedraw();

      const layers = map.getLayers();
      layers.forEach((layer) => {
        if (layer instanceof VectorLayer) {
          const source = layer.getProperties().source;
          const features = source.getFeatures();
          features.forEach((feature) => {
            const properties = feature.getProperties();
            if (
              properties.Name.toLowerCase().includes(
                mcmInput.value.toLowerCase()
              )
            ) {
              map
                .getView()
                .fit(properties.geometry, { easing: 0.5, maxZoom: 12 });
            }
          });
        }
      });
    });
  });
};

// close context menu on mouseout
// unless mcm is rehovered < .3sec
mcm.addEventListener("mouseleave", (event) => {
  const checkMouseOver = () => {
    setTimeout(() => {
      if (!mcm.matches(":hover")) {
        mcm.classList.add("fadeout");
        setTimeout(() => {
          mcm.classList.remove("open");
          mcm.classList.remove("fadeout");
          clearInterval(checkMouseOver);
        }, 100);
      }
    }, 300);
  };
  checkMouseOver();
  clearInterval(checkMouseOver);
});

// open context menu
mapFrame.addEventListener("contextmenu", (event) => {
  console.log(event);
  event.preventDefault();
  mcmRedraw();
  mcm.classList.add("open");
  const toolbarBBox = toolBar.getBoundingClientRect();
  const toolBarXY = [toolbarBBox.x, toolbarBBox.y];
  console.log(toolBarXY);
  mcm.style.left = event.clientX - toolBarXY[0] - 55 + "px";
  mcm.style.top = event.clientY - toolBarXY[1] - 10 + "px";
});

export { mcm, mcmRedraw };
