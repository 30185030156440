/////////////////////////////////////////
//  N A T O   P O I N T   P I C K E R  //
/////////////////////////////////////////

// NATO point generation in the mcm

//////////////////////
//  I M P O R T S   //
//////////////////////`

// M G R S
import Mgrs, { LatLon } from "geodesy/mgrs.js";

// P A G E S
import nspSections from "./nsp/nspSections";

// S E L E C T

// C U S T O M
import toast from "./toast";
import { mcm } from "./mapMcm";
import layerControllerRefresh from "./mapLayerControl";
import ms from "milsymbol";
import { filterLayerByName, coordToMGRS } from "./utils";

// O P E N   L A Y E R S
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import Style from "ol/style/Style";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Icon from "ol/style/Icon";
import { getUid } from "ol/util";
import map from "./initMap";
import { click } from "ol/events/condition";

// BEGINS // -->

const placeMilPoint = () => {
  const nspContainer = document.querySelector("#map-nsp-container");
  // if the right click menu is open, close it
  mcm.classList.contains("open") && mcm.classList.toggle("open");

  // clear the nspContainer
  nspContainer.innerHTML = "";

  // symbol preview
  const nspSymbol = document.createElement("div");
  nspSymbol.id = "map-nsp-milsym";

  nspContainer.append(nspSymbol);

  // S I D C   A N D   P A G E   C H A N G E S

  // C H A N G I N G   T H E   P A G E
  // using MIL-STD-2525D
  // 10 31 00 00 0 01 10 11 70 00 0
  // 10031000000000000000

  let milSIDC = {
    VERSION: "10", // version:
    "STANDARD IDENTITY 1": "0", // standardIdentity1
    "STANDARD IDENTITY 2": "3", // standardIdentity2
    "SYMBOL SET": "10", // symbolSet
    STATUS: "0", //status
    "HQ / TASK FORCE / DUMMY": "0", //hqTFdummy
    "AMPLIFIER / DESCRIPTOR": "00", // amplifier
    ENTITY: "00", // entity
    "ENTITY TYPE": "00", // entityType
    "ENTITY SUBTYPE": "00", // entitySubtype
    "SECTION 1 MODIFIER": "00", // section1Modifier
    "SECTION 2 MODIFIDER": "00", // section2Modifier
  };

  const resolveSIDC = (sidc) => {
    return [
      sidc.VERSION,
      sidc["STANDARD IDENTITY 1"],
      sidc["STANDARD IDENTITY 2"],
      sidc["SYMBOL SET"],
      sidc.STATUS,
      sidc["HQ / TASK FORCE / DUMMY"],
      sidc["AMPLIFIER / DESCRIPTOR"],
      sidc.ENTITY,
      sidc["ENTITY SUBTYPE"],
      sidc["SECTION 1 MODIFIER"],
      sidc["SECTION 2 MODIFIDER"],
    ].join("");
  };

  // C O R E   S Y M B O L   S E T T I N G S

  // group all the inputs
  const milSymInputGrp = document.createElement("div");
  milSymInputGrp.id = "map-nsp-milsym-core-grp";
  nspContainer.append(milSymInputGrp);

  Object.keys(nspSections).forEach((section) => {
    // create page elements
    const milsymCoreContainer = document.createElement("div");
    milsymCoreContainer.className = "map-nsp-input-container";
    const values = nspSections[section];
    const heading = document.createElement("h4");
    heading.innerHTML = section;
    const input = document.createElement("input");
    input.className = "map-nsp-input";
    const results = document.createElement("div");
    results.className = "map-nsp-input-results";
    results.dataset.sidcSection = section;
    results.style.display = "none";

    // add it all
    milSymInputGrp.append(milsymCoreContainer);
    milsymCoreContainer.append(heading);
    milsymCoreContainer.append(input);
    milsymCoreContainer.append(results);
    input.dataset.sidcSection = section;

    // set the default input value
    input.value = Object.keys(values).filter(
      (key) => values[key] === milSIDC[section]
    );

    const buildOptions = () => {
      Object.keys(values).forEach((v) => {
        // vars
        const valueName = v;
        const value = values[v];
        // create the symbol
        const demoSIDC = JSON.parse(JSON.stringify(milSIDC));
        demoSIDC[section] = value;
        const icon = new ms.Symbol(resolveSIDC(demoSIDC), { size: 25 }).asSVG();
        // create a result container
        const result = document.createElement("div");
        result.dataset.name = valueName;
        result.classList.add("map-nsp-result");
        // and a sub container to hold the symbol
        const valueSymbolContainer = document.createElement("div");
        valueSymbolContainer.innerHTML = icon;
        // and a sub containe to hold the value name
        const valueContainer = document.createElement("div");
        valueContainer.innerHTML = valueName;
        // add them to the container
        result.append(valueSymbolContainer);
        result.append(valueContainer);
        results.append(result);
        result.addEventListener("click", () => {
          input.value = valueName;
          milSIDC[section] = value;
          nspMilSymRender();
          results.style.display = "none";
        });
      });
    };

    input.addEventListener("click", (e) => {
      e.target.select();
      document
        .querySelectorAll(".map-nsp-input-results")
        .forEach((r) => (r.style.display = "none"));
      results.innerHTML = "";
      buildOptions();
      let open = open ? false : true;
      if (open) {
        input.style.borderRadius = "5px 5px 0 0";
        results.style.display = "";
      } else {
        input.style.borderRadius = "5px";
        results.style.display = "none";
      }
    });

    input.addEventListener("keyup", () => {
      const search = input.value;
      const res = results.querySelectorAll(".map-nsp-result");
      results.style.display = "";
      res.forEach((result) => {
        const value = result.dataset.name;
        result.style.display = value
          .toLowerCase()
          .includes(search.toLowerCase())
          ? ""
          : "none";
      });
    });
  });

  // P O I N T   G R I D   S E T T I N G S

  // container for everything
  const milsymGridContainer = document.createElement("div");
  milsymGridContainer.id = "map-nsp-grid-container";
  // label for the grid input
  const pGridLbl = document.createElement("span");
  pGridLbl.innerHTML = "ACTIVE MGRS GRID";
  // grid input
  const pGridInput = document.createElement("input");
  pGridInput.id = "map-nsp-grid-input";
  pGridInput.type = "text";
  pGridInput.value = document.querySelector(
    "#map-coord-display-center"
  ).innerHTML;
  pGridInput.placeholder = "ACTIVE MGRS GRID";
  pGridInput.onclick = () => {
    pGridInput.focus(), pGridInput.select();
  };
  // grid helpers
  const func1 = document.createElement("div");
  func1.className = "map-nsp-grid-button";
  func1.innerHTML = "Map Center";
  func1.addEventListener("click", () => {
    toast("Setting map center as new point MGRS")(
      (pGridInput.value = document.querySelector(
        "#map-coord-display-center"
      ).innerHTML)
    );
  });
  const func2 = document.createElement("div");
  func2.className = "map-nsp-grid-button";
  func2.innerHTML = "Mouse Click";
  let clickTracing = false;
  const getMouseCoord = (event) =>
    (pGridInput.value = coordToMGRS(event.coordinate));
  func2.addEventListener("click", () => {
    clickTracing = clickTracing ? false : true;
    if (clickTracing) {
      map.on("singleclick", getMouseCoord);
      toast("Enabled mouse click to set new point MGRS");
      func2.style.border = "1px solid red";
    } else {
      map.un("singleclick", getMouseCoord);
      toast("Disabled mouse click MGRS updating");
      func2.style.border = "1px solid black";
    }
  });

  // container to house the input and buttons
  const gridInputContainer = document.createElement("div");
  gridInputContainer.id = "map-nsp-grid-entry-container";

  nspContainer.append(milsymGridContainer);
  milsymGridContainer.append(pGridLbl);
  milsymGridContainer.append(gridInputContainer);
  gridInputContainer.append(pGridInput);
  gridInputContainer.append(func1);
  gridInputContainer.append(func2);

  // S E C O N D A R Y   S Y M B O L   S E T T I N G S

  const milsymSettingsContainer = document.createElement("div");
  milsymSettingsContainer.id = "map-nsp-secondary-grp";
  // point name
  const pNameContainer = document.createElement("div");
  pNameContainer.className = "map-nsp-input-container";
  const pName = document.createElement("input");
  pName.type = "text";
  pName.placeholder = "NATO Icon";
  const pNameLabel = document.createElement("h4");
  pNameLabel.innerHTML = "NATO Symbol Name";

  // comments
  const pCommentsContainer = document.createElement("div");
  pCommentsContainer.className = "map-nsp-input-container";
  const pComments = document.createElement("input");
  pComments.type = "text";
  pComments.placeholder = "Staff Comments";
  pComments.value = "";
  const pCommentsLabel = document.createElement("h4");
  pCommentsLabel.innerHTML = "Staff Comments";

  // point size
  const pSizeContainer = document.createElement("div");
  pSizeContainer.className = "map-nsp-input-container";
  const pSize = document.createElement("input");
  pSize.type = "number";
  pSize.placeholder = "MGRS Symbol Size";
  pSize.min = "2";
  pSize.value = "30";
  const pSizeLabel = document.createElement("h4");
  pSizeLabel.innerHTML = "Symbol Size";

  // point quantity
  const pQuantityContainer = document.createElement("div");
  pQuantityContainer.className = "map-nsp-input-container";
  const pQuantity = document.createElement("input");
  pQuantity.type = "number";
  pQuantity.placeholder = "Quantity";
  pQuantity.min = "0";
  pQuantity.value = "0";
  const pQuantityLabel = document.createElement("h4");
  pQuantityLabel.innerHTML = "Quantity";

  // place them
  pNameContainer.append(pName);
  pNameContainer.append(pNameLabel);
  pQuantityContainer.append(pQuantity);
  pQuantityContainer.append(pQuantityLabel);
  pSizeContainer.append(pSize);
  pSizeContainer.append(pSizeLabel);
  pCommentsContainer.append(pComments);
  pCommentsContainer.append(pCommentsLabel);
  milsymSettingsContainer.append(pNameContainer);
  milsymSettingsContainer.append(pQuantityContainer);
  milsymSettingsContainer.append(pSizeContainer);
  milsymSettingsContainer.append(pCommentsContainer);
  nspContainer.append(milsymSettingsContainer);

  // U T I L I T Y   B U T T O N S
  const nspReset = document.createElement("button");
  nspReset.innerHTML = "Reset";
  nspReset.onclick = () => {
    toast("Resetting point placement settings");
    placeMilPoint();
  };

  // cancel the point picker
  const nspCancel = document.createElement("button");
  nspCancel.id = "map-nsp-cancel";
  nspCancel.className = "cancel";
  nspCancel.innerHTML = "Close";
  // close the nsp menu
  nspCancel.addEventListener("click", () => {
    nspContainer.style.display = "none";
  });

  // place the point
  const pGridBtn = document.createElement("button");
  pGridBtn.textContent = "Place Point";

  nspContainer.append(pGridBtn);
  nspContainer.append(nspReset);
  nspContainer.append(nspCancel);

  // S Y M B O L   R E N D E R
  const nspMilSymRender = () => {
    const opts = {
      size: pSize.value,
      staffComments: pComments.value,
      quantity: pQuantity.value == 0 ? "" : pQuantity.value,
      additionalInformation: pName.value,
    };
    nspSymbol.innerHTML = "";
    let nspMilSymbol = new ms.Symbol(resolveSIDC(milSIDC), { ...opts });
    nspSymbol.append(nspMilSymbol.asCanvas());
    return nspMilSymbol;
  };

  nspContainer.querySelectorAll("input").forEach((input) => {
    input.addEventListener("input", () => nspMilSymRender());
  });

  nspMilSymRender();

  //////////////////////////////////////
  //  P L A C I N G   A    P O I N T  //
  //////////////////////////////////////

  pGridBtn.addEventListener("click", () => {
    const natoLayerName = "NATO";
    const natoLayerGroup = "User Layer";
    const mgrs = Mgrs.parse(pGridInput.value);
    const latlon = mgrs.toUtm().toLatLon();
    // convert the grid to lat long
    toast(`Placing NATO Sym at MGRS: ${mgrs}`);

    // check if the layer exists already

    let natoLayer = filterLayerByName(natoLayerName);

    if (!natoLayer) {
      console.log("Info: New NATO Layer being created.");
      // NATO layer
      natoLayer = new VectorLayer({
        source: new VectorSource(),
      });
      natoLayer.set("group", natoLayerGroup);
      natoLayer.set("type", "Vector");
      natoLayer.set("Name", natoLayerName);
      map.addLayer(natoLayer);
    }

    const natoSource = natoLayer.getSource();

    // C R E A T E   N A T O   F E A T U R E
    const natoFeature = new Feature({
      geometry: new Point(fromLonLat([latlon._lon, latlon._lat])),
      size: 10,
    });

    // create the symbol
    let nspMilSymbol = nspMilSymRender();

    natoFeature.setStyle(
      new Style({
        image: new Icon({
          imgSize: [
            nspMilSymbol.getSize().width,
            nspMilSymbol.getSize().height,
          ],
          img: nspMilSymbol.asCanvas(),
          anchor: [nspMilSymbol.getAnchor().x, nspMilSymbol.getAnchor().y],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
        }),
      })
    );
    natoSource.addFeature(natoFeature);

    natoFeature.set("Point Style", "Image");
    natoFeature.setId(getUid(natoFeature));
    natoFeature.set(
      "Name",
      pName.value != "" ? pName.value : getUid(natoFeature)
    );

    // add the layer, source, feature
    layerControllerRefresh();
  });

  // finally open the container
  nspContainer.style.display = "flex";
};

export default placeMilPoint;
