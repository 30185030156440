import { mapGroupToID } from "../../utils";

const containerItemGroupTemplate = (groupName) => {
  const layerGroupID = mapGroupToID(groupName);
  return `
    <div class="map-layer-container-group" id="${layerGroupID}">
      <h5>${groupName}</h5>
    </div>
  `.trim();
};

export default containerItemGroupTemplate;
