//  fun-fact, octal literals -> 01 is bad, use 0o1
//  https: /  / www.javascripttutorial.net / es6 / octal-and-binary-literals /
//  if this ever changes to intergers keep it in mind ;)

const nspSections = {
  "STANDARD IDENTITY 1": {
    Reality: "0",
    Exercise: "1",
    Simulation: "2",
  },
  "STANDARD IDENTITY 2": {
    Pending: "0",
    Unknown: "1",
    "Assumed Friend": "2",
    Friend: "3",
    Neutral: "4",
    "Suspect / Joker": "5",
    "Hostile / Faker": "6",
  },
  "SYMBOL SET": {
    Unknown: "00",
    Air: "01",
    "Air Missile": "02",
    "Land Unit": "10",
    "Land Civilian Unit / Org": "11",
    "Land Equipment": "15",
    "Land Installation": "20",
    // "Control Measure": "25",
    "Sea Surface": "30",
    "Sea Subsurface": "35",
    "Mine Warfare": "36",
    Activities: "40",
    "SIGINT - Air": "51",
    "SIGINT - Land": "52",
    Cyberspace: "60",
  },
  STATUS: {
    Present: "0",
    "Planned / Anticipated / Suspect": "1",
    "Present / Fully Capable": "2",
    "Present / Damaged": "3",
    "Present / Destroyed": "4",
    "Present / Full To Capacity": "5",
  },
  "HQ / TASK FORCE / DUMMY": {
    Unknown: "0",
    "Feint / Dummy": "1",
    Headquarters: "2",
    "Feint / Dummy Headquarters": "3",
    "Task Force": "4",
    "Feint / Dummy Task Force": "5",
    "Task Force Headquarters": "6",
    "Feint / Dummy Task Force Headquarters": "7",
  },
  "AMPLIFIER / DESCRIPTOR": {
    Unknown: "00",
    "Team / Crew": "11",
    Squad: "12",
    Section: "13",
    "Platoon / detachment": "14",
    "Company / battery / troop": "15",
    "Battalion / squadron": "16",
    "Regiment / group": "17",
    Brigade: "18",
    Division: "21",
    "Corps / MEF": "22",
    Army: "23",
    "Army Group / front": "24",
    "Region / Theater": "25",
    Command: "26",
  },
};

export default nspSections;
