///////////////////////
//  I N I T   M A P  //
///////////////////////

// Initialize the map with its core features

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N L A Y E R S
import "ol/ol.css";
import { Map, View } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { defaults as defaultControls } from "ol/control";
import XYZ from "ol/source/XYZ";
import BingMaps from "ol/source/BingMaps";
import Stamen from "ol/source/Stamen";

//  BEGIN  //

const generateMap = (() => {
  // base div container
  const mapContainer = document.querySelector("#map-container");

  // dynamic elements
  mapContainer.innerHTML = `
    <div id="map-toolbar"></div>
    <div id="map">
      <div id="map-mcm-menu"></div>
      <div id="map-overview-container"></div>
      <div id="map-coord-container">
        <div class="map-coord-value geo-overlay" id="map-cursor-coordinate-container">
          <span class="map-crosshair-icon">
            &#x002B;
          </span>
          <span id="map-coord-display-MGRS">00A BC 12345 67890</span>
        </div>
        <div class="map-coord-value">
          <span class="map-crosshair-icon" style="color: red">
            &#x002B;
          </span>
          <span id="map-coord-display-center">00A BC 12345 67890</span>
        </div>
      </div>
      <div id="map-scalebar"></div>
      <div id="map-center-crosshair">
        <span>&#x002B;</span>
      </div>
      <div id="map-layer-control" class="geo-overlay"></div>
      <div id="map-layer-settings-container" class="geo-overlay"></div>
      <div id="map-feature-settings-container" class="geo-overlay"></div>
      <div id="map-layer-list-features" class="geo-overlay"></div>
      <div id="map-nsp-container" class="geo-overlay"></div>
      <div id="map-popup-container" class="geo-overlay"></div>
    </div>`;
})();

// M A P   D I V
const mapDiv = document.querySelector("#map");

///////////////////////////
//  M A P   C A N V A S  //
///////////////////////////

const osmBasemap = new TileLayer({
  source: new OSM(),
});
osmBasemap.set("Name", "OSM");
osmBasemap.set("group", "Basemap Layer");
osmBasemap.set("type", "Raster");

const bingMaps = new TileLayer({
  source: new BingMaps({
    key: "Artoo-VCtq8kq-KBo32986WBp5h33fmBPA_8csKyZs_B6UFp4OhCBleUs-Le1Jzu",
    imagerySet: "Aerial",
  }),
});

bingMaps.set("Name", "Bing - Imagery");
bingMaps.set("group", "Basemap Layer");
bingMaps.set("type", "Raster");
bingMaps.setVisible(false);

const stamenTopo = new TileLayer({
  source: new Stamen({
    layer: "terrain",
    maxZoom: 15,
  }),
});
stamenTopo.set("Name", "Stamen - Topo");
stamenTopo.set("group", "Basemap Layer");
stamenTopo.set("type", "Raster");
stamenTopo.setVisible(false);

const googleMapImagery = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    crossOrigin: "Anonymous",
  }),
});
googleMapImagery.set("Name", "Google - Imagery");
googleMapImagery.set("group", "Basemap Layer");
googleMapImagery.set("type", "Raster");
googleMapImagery.setVisible(false);

const googleMapImageryHybrid = new TileLayer({
  source: new XYZ({
    url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
    crossOrigin: "Anonymous",
  }),
});
googleMapImageryHybrid.set("Name", "Google - Hybrid");
googleMapImageryHybrid.set("group", "Basemap Layer");
googleMapImageryHybrid.set("type", "Raster");
googleMapImageryHybrid.setVisible(false);

// A Z U R E
//////////////

// azure subscription key
const subscriptionKey = "6cK8lgK0rUfe1_fbQOnbluIDZng7v6DLGv0_KtvDfR0";

const azureMapImagery = new TileLayer({
  source: new XYZ({
    url: `https://atlas.microsoft.com/map/tile?subscription-key=${subscriptionKey}&api-version=2.0&tilesetId=microsoft.imagery&zoom={z}&x={x}&y={y}&tileSize=256&language=en-US&view=Auto`,
    crossOrigin: "Anonymous",
  }),
});
azureMapImagery.set("Name", "Azure - Imagery");
azureMapImagery.set("group", "Basemap Layer");
azureMapImagery.set("type", "Raster");
azureMapImagery.setVisible(false);

const seamark = new TileLayer({
  source: new XYZ({
    url: `https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png`,
    maxZoom: 15,
  }),
});
seamark.set("Name", "Seamark");
seamark.set("group", "Basemap Layer");
seamark.set("type", "Raster");
seamark.setVisible(false);

const map = new Map({
  target: mapDiv,
  layers: [
    osmBasemap,
    //stamenTopo,
    bingMaps,
    googleMapImagery,
    //googleMapImageryHybrid,
    azureMapImagery,
    seamark,
  ],
  // interactions: defaultInteractions().extend([select, translate]),
  controls: defaultControls({ attribution: false, zoom: false }),
  renderer: "webgl",
  view: new View({
    projection: "EPSG:3857",
    center: fromLonLat([31.1, 48.3]),
    zoom: 5,
  }),
});

export default map;
