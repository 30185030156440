///////////////
//  M A I N  //
///////////////

// import and initiate all the map partials

/////////////////////
//  I M P O R T S  //
/////////////////////

// C U S T O M
import map from "./map/initMap";
import "./map/initMap";
import "./map/mapCrosshair";
import "./map/mapLocationDiagram";
import "./map/mapControls";
import "./map/mapKmz";
import "./map/mapBaseLayers";
import "./map/mapShortcuts";
import "./map/mapExport";
import "./map/mapPopup";
import layerControllerRefresh from "./map/mapLayerControl";
//import "./map/mapDraw";  -- Not yet implemented
import { mcm } from "./map/mapMcm";

// BEGINS // -->

// refresh the layer controller
layerControllerRefresh();
