///////////////////////////
//  K M Z   H E L P E R  //
///////////////////////////

// Add the KMZ button to the toolbar and call
// the appropriate scripts

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N   L A Y E R S
import { Feature, Map, Overlay, View } from "ol";

// C U S T O M
import { kmzParser } from "./kmzParser";
import toolBar from "./mapToolbar";
import layerControllerRefresh from "./mapLayerControl";

// BEGINS // -->

// control to toggle the layer selector
const fileUploadButton = document.createElement("div");
fileUploadButton.setAttribute("data-tooltip", "Upload a KMZ File");
fileUploadButton.innerHTML = "KMZ";
const kmzUploadContainer = document.createElement("div");
kmzUploadContainer.className = "ol-unselectable ol-control";
kmzUploadContainer.appendChild(fileUploadButton);
// add it to the toolbar
toolBar.appendChild(kmzUploadContainer);

fileUploadButton.addEventListener("click", () => {
  const kmzUploadInput = document.createElement("input");
  kmzUploadInput.type = "file";
  kmzUploadInput.setAttribute("accept", ".kmz,.kml");
  kmzUploadInput.hidden = true;
  document.body.append(kmzUploadInput);
  kmzUploadInput.addEventListener("change", () => {
    // manage the file
    const file = kmzUploadInput.files[0];
    if (file) {
      const fileName = file.name.split(".")[0];
      console.log(`Info: Uploaded KMZ Name: ${fileName}\n`);
      kmzParser(file);
      layerControllerRefresh();
    }
    kmzUploadInput.remove();
  });
  kmzUploadInput.click();
});
