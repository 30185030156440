export class RaptrGeo extends HTMLElement {
  connectedCallback() {
    // setTimeout will ensure the css/js only get loaded after the element is mounted to the DOM
    setTimeout(() => {
      this.innerHTML = `<div id="map-container"></div>`;

      require("./css/main.scss");
      require("./js/natosym/milsymbol.js");
      require("./js/main.js");
    });
  }
}

window.customElements.define("raptr-geo", RaptrGeo);
