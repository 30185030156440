const layerSettingsTemplate = (layer) => {
  const layerSettings = layer.getKeys();
  const layerName = layer.get("Name");
  const ignoreKeys = [
    "source",
    "type",
    "group",
    "zIndex",
    "minZoom",
    "maxZoom",
    "visible",
    "useInterimTilesOnError",
    "preload",
    "maxResolution",
    "minResolution",
    "name",
  ];

  return `
    <h4>${layerName} Settings</h4>
    <div>
      ${layerSettings
        .map((setting) => {
          // iterate settings, skipping some bad ones
          if (!ignoreKeys.includes(setting)) {
            const regex = /([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g;
            const name = setting.replace(regex, "$& ");
            return `
              <div>
                <label for="${setting}">${name}</label>
                <input 
                  type="text"
                  id="${setting}"
                  placeholder="${layer.get(setting)}"
                  value="${layer.get(setting)}">
                </input>
              </div>
            `;
          }
        })
        .join("")}
    </div>
    
    <div>
      <button id="map-layer-settings-submit">Apply</button>
      <button id="map-layer-settings-cancel" class="cancel">Cancel</button>
    </div>
    `;
};

export default layerSettingsTemplate;
