/////////////////////
//  T O O L B A R  //
/////////////////////

// Generate the toolbar reference

// BEGINS // -->

const toolBar = document.querySelector("#map-toolbar");

export default toolBar;
