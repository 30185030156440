import { getUid } from "ol/util";

// populating the layer control widget
const containerItemTemplate = (layer) => {
  const layerName = layer.get("Name");
  const layerType = layer.get("type");
  const layerID = getUid(layer);
  const isRaster = layerType === "Raster";

  // the raw html for creating a layer controller item
  const visible = layer.get("visible");
  return `
    <div class="map-layer-container-item">
      <div class="map-layer-container-main">
        <label class="map-layer-container-item-switch">
          <input
            class="map-layer-toggle"
            type="checkbox"
            data-layer="${layerID}"
            ${visible ? "checked" : ""}
          >
          <span class="map-layer-container-item-slider round"></span>
        </label>
        <span class="map-layer-container-item-name">${layerName}</span>
      </div>
      <div data-type="${layerType}" class="map-layer-container-item-toolbar hidden">
        ${
          isRaster
            ? ""
            : `
            <div class="map-layer-toolbar-item" data-tool="layerZoomTo" data-layer="${layerName}" >
              <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="map-feature-list-feature-tool"><path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm6.32-1.094l3.58 3.58a1 1 0 1 1-1.415 1.413l-3.58-3.58a8 8 0 1 1 1.414-1.414z"></path></svg>
            </div>
          `
        }
        <div class="map-layer-toolbar-item" data-tool="layerSettings" data-layer="${layerName}">
          <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 24 24" width="24" height="24" preserveAspectRatio="xMinYMin"><path d="M2.464 6.748c.06.942.45 1.865 1.164 2.578a3.997 3.997 0 0 0 3.866 1.036l1.114-.298 9.162 9.161a1 1 0 0 0 1.414-1.414L10.022 8.65l.298-1.115A3.997 3.997 0 0 0 9.284 3.67a3.995 3.995 0 0 0-2.578-1.164L7.93 3.728A3 3 0 1 1 3.686 7.97L2.464 6.748zm-.9-3.727L5.1 6.556a1 1 0 0 0 1.415-1.414L2.979 1.606a6.002 6.002 0 0 1 9.273 6.445l8.346 8.346a3 3 0 0 1-4.243 4.243L8.01 12.294A6.002 6.002 0 0 1 1.565 3.02zm15.5 15.496l1.42-1.41-1.42-1.414-1.419 1.414 1.418 1.41z"></path></svg>
        </div>
 
        ${
          isRaster
            ? ""
            : `
            <div class="map-layer-toolbar-item" data-tool="layerFeatures" data-layer="${layerName}">
              <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="24" height="24" preserveAspectRatio="xMinYMin"><path d="M6 0h8a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 2a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6zm6 7h3a1 1 0 0 1 0 2h-3a1 1 0 0 1 0-2zm-2 4h5a1 1 0 0 1 0 2h-5a1 1 0 0 1 0-2zm0-8h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2zm-4.172 5.243L7.95 8.12a1 1 0 1 1 1.414 1.415l-2.828 2.828a1 1 0 0 1-1.415 0L3.707 10.95a1 1 0 0 1 1.414-1.414l.707.707z"></path></svg>
            </div>
          `
        }
        <div class="map-layer-toolbar-item" data-tool="layerRemove" data-layer="${layerName}">
          <svg style="pointer-events: none" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 24 24" width="44" height="24" preserveAspectRatio="xMinYMin"><path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path><path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path></svg>
        </div>
      </div>
    </div>
`.trim();
};

export default containerItemTemplate;
