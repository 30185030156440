// O P E N   L A Y E R S
import Select from "ol/interaction/Select";
import Translate from "ol/interaction/Translate";
import Collection from "ol/Collection";
import { getUid } from "ol/util";

// C U S T O M
import map from "../initMap";
import toast from "../toast";
import { filterLayerByName } from "../utils";
import layerControllerRefresh from "../mapLayerControl";

// S N I P P E T S
import featureItemTemplate from "./snippets/featureItemTemplate";
import layerSettingsTemplate from "./snippets/layerSettingsTemplate";

// S T Y L I N G
import featureStyler from "./featureStyler";

// BEGINS // -->

// G L O B A L   V A R S
const featureListContainer = document.querySelector("#map-layer-list-features");
const layerControlContainer = document.querySelector("#map-layer-control");

// R E D U X
//////////////////////////////////////////////////////////////////////////
const addLayerControlLogic = () => {
  //////////////////////////////
  //  L A Y E R    T O O L S  //
  //////////////////////////////

  layerControlContainer.addEventListener("click", (event) => {
    const tool = event.target.dataset.tool;
    const layerName = event.target.dataset.layer;
    const layer = filterLayerByName(layerName);
    // if there's a tool being clicked
    if (tool) {
      if (tool === "layerZoomTo") {
        map.getView().fit(layer.getSource().getExtent(), {
          size: map.getSize(),
          padding: [250, 250, 250, 250],
          maxZoom: 16,
        });
        toast(`Zooming to the extent of ${layerName}`);
      } else if (tool === "layerSettings") {
        //
        // L A Y E R   S E T T I N G S
        //
        // find the existing html
        const layerContainer = document.querySelector(
          "#map-layer-settings-container"
        );

        // show it
        layerContainer.style.display = "flex";
        //
        // -- GET ALL THE LAYER SETTINGS: icon size, color, etc
        //
        layerContainer.innerHTML = layerSettingsTemplate(layer);
        // listen for submit, apply the data
        const layerSettingsSubmit = document.querySelector(
          "#map-layer-settings-submit"
        );
        layerSettingsSubmit.addEventListener("click", () => {
          const settings = layerContainer.querySelectorAll("input");
          settings.forEach((setting) => {
            const set = setting.id;
            const value = setting.value;
            layer.set(set, value);
          });

          // Update the layer controller
          layerControllerRefresh();
          // close the menu
          layerContainer.style.display = "";
          toast(`Applying settings for layer ${layerName}`);
        });
        // listen for close, shut down the menu
        const layerSettingsCancel = document.querySelector(
          "#map-layer-settings-cancel"
        );
        layerSettingsCancel.className = "cancel";
        layerSettingsCancel.addEventListener("click", () => {
          layerContainer.style.display = "";
          toast(`Canceling settings for layer ${layerName}`);
        });
        toast(`Opening settings for layer ${layerName}`);
      } else if (tool === "layerFeatures") {
        const features = layer.getSource().getFeatures();
        toast(`Listing all features for layer ${layerName}`);

        // clear the container so it's not doulbing up
        // and clear the event listners
        featureListContainer.innerHTML = "";

        // iterate features generating an html snippet and appending it to the feature list
        features
          .sort((featA, featB) =>
            featA.get("Name").localeCompare(featB.get("Name"))
          )
          .forEach((feature) => {
            const featureCompiledItem = featureItemTemplate(feature, layer);
            featureListContainer.insertAdjacentHTML(
              "beforeend",
              featureCompiledItem
            );
          });

        // feature list close button
        // close and clear the feature list container
        const featureListContainerClose = document.createElement("button");
        featureListContainerClose.id = "feature-list-container-close";
        featureListContainerClose.innerHTML = `✖`;
        featureListContainerClose.addEventListener("click", () => {
          featureListContainer.style.display = "";
          featureListContainer.innerHTML = "";
        });

        // feature list search
        const featureListContainerSearch = document.createElement("input");
        featureListContainerSearch.id = "feature-list-container-search";
        featureListContainerSearch.placeholder = "Search to filter features...";
        // fit the container to the results
        featureListContainer.addEventListener("keyup", () => {
          const width =
            featureListContainer.getBoundingClientRect().width + "px";
          const inputValue = featureListContainerSearch.value.toLowerCase();
          const featureItemTemplates = featureListContainer.querySelectorAll(
            ".map-feature-list-feature-container"
          );
          let featureCounter = 0;
          const maxLoaded = 10;
          featureItemTemplates.forEach((item) => {
            if (featureCounter >= maxLoaded) {
              item.style.display = "none";
            } else {
              if (!item.dataset.search.toLowerCase().includes(inputValue)) {
                item.style.display = "none";
              } else {
                item.style.display = "";
                featureCounter++;
              }
              featureListContainer.style.width = width;
            }
          });
          console.log(`Info: Searching for ${inputValue}...`);
          featureCounter >= maxLoaded &&
            toast(
              "Max returned features exceeds limit, please narrow search..."
            );
        });

        // add the interactives
        const featureListTool = document.createElement("div");
        featureListTool.id = "map-feature-list-tool-container";
        featureListTool.prepend(featureListContainerClose);
        featureListTool.prepend(featureListContainerSearch);
        featureListContainer.prepend(featureListTool);
        // show the list container
        featureListContainer.style.display = "flex";
      } else if (tool === "layerRemove") {
        map.removeLayer(layer);
        layerControllerRefresh();
        document.querySelector("#map-layer-list-features").style.display = "";
      }
    }
  });

  // E N D   L A Y E R   T O O L S

  /////////////////////////////////
  //  F E A T U R E   T O O L S  //
  /////////////////////////////////
  featureListContainer.addEventListener("click", (event) => {
    const tool = event.target.dataset.tool;
    if (tool) {
      const featureUid = event.target.dataset.featureuid;
      const featureName = event.target.dataset.featurename;
      const layerName = event.target.dataset.layer;
      const layer = filterLayerByName(layerName);
      const source = layer.getSource();
      const feature = source.getFeatureById(featureUid);

      if (tool === "zoom") {
        //  Z O O M   T O   F E A T U R E
        map.getView().fit(feature.getGeometry().getExtent(), {
          size: map.getSize(),
          padding: [50, 50, 50, 50],
          maxZoom: 16,
        });
        toast(`Zooming to the extent of ${featureName}`);
      } else if (tool === "move") {
        //  M O V E   F E A T U R E
        // move to the target feature
        map.getView().fit(feature.getGeometry().getExtent(), {
          size: map.getSize(),
          padding: [250, 250, 250, 250],
          maxZoom: 16,
        });
        const collection = new Collection();
        const select = new Select({
          features: collection,
        });

        // add the select and translate interaction
        map.addInteraction(select);
        collection.push(feature);

        // 'select' our feature
        select.dispatchEvent({
          type: "select",
          selected: [feature],
          deselected: [],
        });

        // create translate function on our selected feature
        const translate = new Translate({
          features: select.getFeatures(),
        });

        // add the interaction to the map
        map.addInteraction(translate);
        select.on("select", (evt) => {
          map.removeInteraction(select);
          map.removeInteraction(translate);
        });

        toast(`Moving feature ${featureName}`);
      } else if (tool === "style") {
        //  S T Y L E   F E A T U R E

        featureStyler(feature);
      } else if (tool === "delete") {
        // D E L E T E   F E A T U R E

        // remove the feature from the layer selector
        featureListContainer
          .querySelector(`div[data-search="${featureName}"]`)
          .remove();

        source.removeFeature(feature);

        // send toast!
        toast(`Deleting feature ${featureName}...`);
      }
    }
  });
};
// E N D   R E D U X

export default addLayerControlLogic;
