///////////////////////////////////////
//  L O C A T I O N   D I A G R A M  //
///////////////////////////////////////

// Add the location diagram to the map

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N   L A Y E R S
import { Feature, View, Map } from "ol";
import Polyline from "ol/geom/Polygon";
import {
  getBottomLeft,
  getBottomRight,
  getTopLeft,
  getTopRight,
} from "ol/extent";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Stroke, Style } from "ol/style";
import * as olEvents from "ol/events";

// C U S T O M
import map from "./initMap";
import toast from "./toast";
import toolBar from "./mapToolbar";

// BEGINS // -->

// get the map extent
const mapExtent = new Feature({
  geometry: new Polyline(
    new Array([
      getBottomLeft(map.getView().calculateExtent()),
      getBottomRight(map.getView().calculateExtent()),
      getTopRight(map.getView().calculateExtent()),
      getTopLeft(map.getView().calculateExtent()),
    ])
  ),
});

// identify the location diagram container
const locationDiagramContainer = document.querySelector(
  "#map-overview-container"
);

// create the location diagram
const locationDiagram = new Map({
  target: locationDiagramContainer,
  controls: defaultControls({
    zoom: false,
    attribution: false,
  }),
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    new VectorLayer({
      source: new VectorSource({
        features: [mapExtent],
      }),
      style: new Style({
        stroke: new Stroke({
          color: "red",
          width: 2.5,
        }),
      }),
    }),
  ],
  view: new View({
    projection: "EPSG:3857",
    center: fromLonLat([-90, 50]),
  }),
});

///////////////////////////////////////////////
//  M A P   P R E R E N D E R   E V E N T S  //
///////////////////////////////////////////////

// on map change, before its composed
// a)   change the location diagrams location
//      to match the main map, with a zoom differential
//      of the Math.round'ed value of the (main map zoom / 6) - 3
//      and update the bounding box
// b)   update the center crosshair

map.on("precompose", () => {
  // update the location diagram
  locationDiagram
    .getView()
    .setZoom(
      Math.round(map.getView().getZoom() - map.getView().getZoom() / 6) - 3
    );
  locationDiagram.getView().setCenter(map.getView().getCenter());
  mapExtent.setGeometry(
    new Polyline(
      new Array([
        getBottomLeft(map.getView().calculateExtent()),
        getBottomRight(map.getView().calculateExtent()),
        getTopRight(map.getView().calculateExtent()),
        getTopLeft(map.getView().calculateExtent()),
      ])
    )
  );
});

// control to toggle the location diagram
const ldButton = document.createElement("div");
ldButton.setAttribute("data-tooltip", "Toggle the Location Diagram widget");
ldButton.innerHTML = "LD";
const ldContainer = document.createElement("div");
ldContainer.className = "ol-unselectable ol-control";
ldContainer.appendChild(ldButton);

ldButton.addEventListener("click", () => {
  const shown = locationDiagramContainer.style.display;
  if (shown === "none") {
    toast("Enabled location diagram");
    locationDiagramContainer.style.display = "";
  } else {
    toast("Disabled location diagram");
    locationDiagramContainer.style.display = "none";
  }
});

toolBar.appendChild(ldContainer);
