/////////////////////////////////
//  L A Y E R   C O N T R O L  //
/////////////////////////////////

// Add the KMZ button to the toolbar and call
// the appropriate scripts

//////////////////////
//  I M P O R T S   //
//////////////////////

// C U S T O M
import toolBar from "./mapToolbar";
import map from "./initMap";
import toast from "./toast";
import { mapGroupToID, filterLayerByName } from "./utils";
import { getUid } from "ol/util";

// M A P   L A Y E R   H T M L   S N I P P E T S
import containerItemGroupTemplate from "./layer_control/snippets/containerItemGroupTemplate";
import containerItemTemplate from "./layer_control/snippets/containerItemTemplate";

// M A P   L A Y E R   L O G I C
import addLayerControlLogic from "./layer_control/addLayerControlLogic";
// add all the logic
addLayerControlLogic();

// BEGINS // -->

// L A Y E R   C O N T R O L
const layerControlContainer = document.querySelector("#map-layer-control");

// A D D   T H E   T O O L B A R   C O N T R O L
const layerButton = document.createElement("div");
layerButton.id = "map-layer-contol-button";
layerButton.setAttribute("data-tooltip", "Toggle the Layer Control widget");
layerButton.innerHTML = "LY";
const layerContainer = document.createElement("div");
layerContainer.className = "ol-unselectable ol-control";
layerContainer.appendChild(layerButton);
// add it to the toolbar
toolBar.appendChild(layerContainer);
// toggle the layer selector panel
layerButton.addEventListener("click", () => {
  const shown = layerControlContainer.style.display;
  if (shown === "none") {
    toast("Showing layer control");
    layerControlContainer.style.display = "";
  } else {
    toast("Hiding layer control");
    layerControlContainer.style.display = "none";
  }
});

// R E S E T   T H E   C O N T R O L L E R   A S   N E E D E D

const layerControllerRefresh = () => {
  // clear the existing data
  layerControlContainer.innerHTML = "";

  // get all the *current* map layers (redraw as needed)
  const mapLayers = map.getLayers().getArray().slice();

  const layerControlGroups = [];

  // generate the map layers, and map groups
  mapLayers.forEach((layer) => {
    // get the group name and it converted to an ID
    const layerGroupName = layer.get("group");
    const layerTypeID = mapGroupToID(layerGroupName);
    // generate the html code with this layer
    const layerItem = containerItemTemplate(layer);
    // check if the group exists (vector/raster/etc)
    if (!layerControlGroups.includes(layerGroupName)) {
      // add the layer group to the array
      layerControlGroups.push(layerGroupName);

      // get the layergroup html and create it
      const layerGroup = containerItemGroupTemplate(layerGroupName);
      layerControlContainer.insertAdjacentHTML("beforeend", layerGroup);

      document
        .querySelector(`#${layerTypeID}`)
        .insertAdjacentHTML("beforeend", layerItem);
    } else {
      document
        .querySelector(`#${layerTypeID}`)
        .insertAdjacentHTML("beforeend", layerItem);
    }
  });

  // add the map layer toggle logic
  const mapLayerToggles = document.querySelectorAll(".map-layer-toggle");
  mapLayerToggles.forEach((toggle) => {
    toggle.addEventListener("change", () => {
      const layerID = toggle.dataset.layer;
      const layers = map.getLayers().getArray().slice();
      layers.forEach((layer) => {
        if (getUid(layer) === layerID) {
          if (!toggle.checked) {
            layer.set("visible", false);
          } else {
            layer.set("visible", true);
          }
        }
      });
    });
  });

  // hide the toolbars ONLY when clicking the layer title
  const mapLayerItems = document.querySelectorAll(".map-layer-container-item");
  mapLayerItems.forEach((layerItem) => {
    const toolbar = layerItem.querySelector(
      ".map-layer-container-item-toolbar"
    );
    if (toolbar) {
      layerItem.addEventListener("click", (event) => {
        !event.target.classList.contains("map-layer-toolbar-item") &&
          toolbar.classList.toggle("hidden");
      });
    }
  });
};

export default layerControllerRefresh;
