




import { Component, Vue } from 'vue-property-decorator';
import '@raptr/geo/raptrGeoWebComponent.js';

@Component
export default class GeoComponent extends Vue {
  $refs!: {
    geoRef: HTMLElement;
  };

  mounted(): void {
    setTimeout(() => {
      const geoElement = document.createElement('raptr-geo');
      this.$refs.geoRef.appendChild(geoElement);
    });
  }
}
