////////////////////////////
//   K M Z   P A R S E R  //
////////////////////////////

// Parse KMZ files for their data and
// add them to the map

/////////////////////
//  I M P O R T S  //
/////////////////////

// Z I P   F I L E   M A N A G E M E N T
import JSZip from "jszip";

// O P E N   L A Y E R S
import map from "./initMap";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import layerControllerRefresh from "./mapLayerControl";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import { Feature } from "ol";
import { Fill, Stroke, Style, Text, Icon } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Polygon from "ol/geom/Polygon";
import Polyline from "ol/geom/Polygon";
import { getUid } from "ol/util";

//  C U S T O M
import toast from "./toast";

//  BEGIN  //

export const kmzParser = (kmzFile) => {
  const zip = new JSZip();
  const filename = kmzFile.name.split(".").pop();

  // duplicate the kmz file with .zip at the end
  const kmzZip = new File([kmzFile], `${filename}.zip`, { type: kmzFile.type });

  // read the KMZ to parse it
  const readKmzDoc = new Promise((resolve) => {
    zip
      .loadAsync(kmzZip)
      .then((data) => {
        return data.files["doc.kml"].async("text");
      })
      .then((kmzDoc) => {
        // begin parsing the doc.kml file
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(kmzDoc, "text/xml");

        // P L A C E M A R K S
        const placemarks = [];
        // final all the placemarks in the doc
        const placemarkElements = xmlDoc.querySelectorAll("Placemark");
        placemarkElements.forEach((pm) => {
          const placemark = {};
          placemark.name = pm.querySelector("name").textContent;
          placemark.style = pm.querySelector("styleUrl").textContent;
          // find all the coordinates, strip the html, and split by ' '
          placemark.coordinates = pm
            .querySelector("coordinates")
            .textContent.replace(/\n|\t/g, "")
            .split(" ")
            .filter((coord) => coord !== "");

          // for each coord pair, remove the z value
          // and cast string to float
          placemark.coordinates = placemark.coordinates.map((coord) => {
            const coordSplit = coord.split(",").slice(0, 2);
            return coordSplit.map((coord) => {
              return parseFloat(coord);
            });
          });

          // get the placemark geometry from its parent node name
          placemark.geometry =
            pm.querySelector("coordinates").parentElement.nodeName;
          placemarks.push(placemark);
        });

        //  S T Y L E S

        // iterate placemarks and find the icon filename
        placemarks.forEach((placemark) => {
          try {
            placemark.styles = {};
            const styleID = placemark.style;
            const styleMap = xmlDoc.querySelector(styleID);
            const stylePair = styleMap.querySelectorAll("Pair")[0];
            const styleURL = stylePair.querySelector("styleUrl").textContent;
            const style = xmlDoc.querySelector(styleURL);
            // iterate the KML styles looking for values
            // will depend on the input geometry
            const styles = ["IconStyle", "LineStyle", "PolyStyle"];
            styles.forEach((selector) => {
              const result = style.querySelector(selector);
              if (result) {
                placemark.styles[selector] = {};
                const styles = result.children;
                Object.keys(styles).forEach((style) => {
                  const tagName = styles[style].tagName;
                  const tagContent = styles[style].textContent.replace(
                    /\t|\n/g,
                    ""
                  );
                  placemark.styles[selector][tagName] = tagContent;
                });
              }
            });
          } catch {
            console.error("Error: Bad Point");
          }
        });
        delete placemarks.style;
        resolve(placemarks);
      });
  });

  // P O I N T   I M A G E S
  const gatherSymbolFiles = new Promise((resolve) => {
    zip
      .loadAsync(kmzZip)
      .then((data) => {
        // with regex find all the images in the zipfile
        // conver them to blob and return them
        const re = /(.jpg|.png|.gif|jpeg|.svg)$/;
        const promises = Object.keys(data.files)
          .filter((file) => {
            return re.test(file);
          })
          .map((img) => {
            const file = data.files[img];
            return file.async("blob").then((blob) => {
              return { img, url: URL.createObjectURL(blob) };
            });
          });
        return Promise.all(promises);
      })
      .then((result) => {
        // we have here an array of [fileName, url]
        resolve(result);
      });
  });

  Promise.all([readKmzDoc, gatherSymbolFiles]).then((values) => {
    // format the returned values
    const data = {
      geometry: values[0],
      images: values[1],
    };

    // console log the extracted data
    console.log("Info: KMZ Extracted Values: ", data);

    // init arrays of styles
    const pointFeatures = [];
    const lineFeatures = [];
    const polygonFeatures = [];

    // create the elements
    data.geometry.forEach((geo) => {
      //
      ///////////////////////////////////
      //  I F   I T S   A   P O I N T  //
      ///////////////////////////////////
      //
      if (geo.geometry === "Point") {
        const feature = new Feature({
          geometry: new Point(fromLonLat(...geo.coordinates)),
          Name: geo.name,
        });

        feature.set("Geometry Type", "Point");

        const style = new Style({
          image: new CircleStyle({
            fill: new Fill({
              color: "#FFFF00",
            }),
            stroke: new Stroke({
              color: "#000",
              width: 2,
            }),
            radius: 10,
          }),
          text: new Text({
            text: geo.name,
            font: "14px Calibri,sans-serif",
            fill: new Fill({
              color: "#fff",
            }),
            stroke: new Stroke({
              color: "#000",
              width: 2,
            }),
            offsetX: 30,
            offsetY: 30,
          }),
        });

        feature.set("Point Style", "Circle");

        // try to find the image
        const image = Object.values(data.images).find((img) => {
          if (geo.styles.IconStyle.Icon === img.img) {
            return img.url;
          }
        });

        // if an image is found, set it
        if (image) {
          style.setImage(
            new Icon({
              src: image.url,
              scale: 0.3,
            })
          );
          feature.set("Point Style", "Image");
        }

        // set Feature style data
        feature.set("Fill Color", "#FFFF00");
        feature.set("Stroke Color", "#000");
        feature.set("Stroke Width", 2);
        feature.set("Radius", 10);
        feature.set("Text", geo.name);
        feature.set("Text Font", "14px Calibri,sans-serif");
        feature.set("Text Color", "#fff");
        feature.set("Text Stroke Color", "#000");
        feature.set("Text Stroke Width", 2);
        feature.set("Text Placement", "line");
        feature.setId(getUid(feature));

        // apply the style and push the point to the feature array
        feature.setStyle(style);
        pointFeatures.push(feature);
      } else if (geo.geometry === "LineString") {
        //
        /////////////////////////////////////////////
        //  I F   I T S   A   L I N E S T R I N G  //
        /////////////////////////////////////////////
        //
        const featureCoords = new Array(
          geo.coordinates.map((coordPair) => {
            return fromLonLat(coordPair);
          })
        );

        const feature = new Feature({
          geometry: new Polyline(featureCoords),
          Name: geo.name,
        });
        feature.set("Geometry Type", "Line");
        const color =
          "#" + geo.styles.LineStyle.color.split("").reverse().join("");
        const style = new Style({
          text: new Text({
            text: geo.name,
            font: "14px Calibri,sans-serif",
            fill: new Fill({
              color: "#fff",
            }),
            placement: "line",
            stroke: new Stroke({
              color: "#000",
              width: 2,
            }),
          }),
          stroke: new Stroke({
            color: color,
            width: geo.styles.LineStyle.width,
          }),
        });

        // set Feature style data
        feature.set("Stroke Color", color);
        feature.set("Stroke Width", geo.styles.LineStyle.width || 2);
        feature.set("Text", geo.name);
        feature.set("Text Font", "14px Calibri,sans-serif");
        feature.set("Text Color", "#fff");
        feature.set("Text Stroke Color", "#000");
        feature.set("Text Stroke Width", 2);
        feature.set("Text Placement", "line");
        feature.setId(getUid(feature));

        feature.setStyle(style);
        lineFeatures.push(feature);
      } else if (geo.geometry === "LinearRing") {
        //
        ///////////////////////////////////////////////
        //  I F   I T S   A   L I N E A R   R I N G  //
        ///////////////////////////////////////////////
        //
        const featureCoords = new Array(
          geo.coordinates.map((coordPair) => {
            return fromLonLat(coordPair);
          })
        );

        const feature = new Feature({
          geometry: new Polygon(featureCoords),
          Name: geo.name,
        });

        feature.set("Geometry Type", "Polygon");

        const fillColor =
          "#" + geo.styles.PolyStyle.color.split("").reverse().join("");
        const strokeColor =
          "#" + geo.styles.LineStyle.color.split("").reverse().join("");
        const style = new Style({
          text: new Text({
            text: geo.name,
            font: "14px Calibri,sans-serif",
            fill: new Fill({
              color: "#fff",
            }),
            placement: "line",
            stroke: new Stroke({
              color: "#000",
              width: 2,
            }),
          }),
          stroke: new Stroke({
            color: strokeColor,
            width: geo.styles.LineStyle.width,
          }),
          fill: new Fill({
            color: fillColor,
          }),
        });

        // set Feature style data
        feature.set("Fill Color", fillColor);
        feature.set("Stroke Color", strokeColor);
        feature.set("Stroke Width", geo.styles.LineStyle.width || 2);
        feature.set("Text", geo.name);
        feature.set("Text Font", "14px Calibri,sans-serif");
        feature.set("Text Color", "#fff");
        feature.set("Text Stroke Color", "#000");
        feature.set("Text Stroke Width", 2);
        feature.set("Text Placement", "line");
        feature.setId(getUid(feature));

        feature.setStyle(style);
        polygonFeatures.push(feature);
      }
    });

    // set unique hashes
    const features = [...pointFeatures, ...lineFeatures, ...polygonFeatures];

    const kmzLayer = new VectorLayer({
      source: new VectorSource({
        features,
      }),
    });

    // create the layer metadata
    const filename = kmzFile.name.split(".")[0];
    kmzLayer.set("Name", filename);
    kmzLayer.set("group", "User File");

    // add that bad boi to the map
    // then zoom to fit it
    map.addLayer(kmzLayer);
    console.log(`Info: ${kmzLayer.get("Name")} Features Loaded`);
    map.getView().fit(kmzLayer.getSource().getExtent(), {
      size: map.getSize(),
      padding: [150, 150, 150, 150],
    });
    layerControllerRefresh();
    toast(`KMZ file named ${kmzLayer.get("Name")} loaded...`);
  });
};
