/////////////////////////////////
//  T O A S T   M A N A G E R  //
/////////////////////////////////

// Generate toast (popup) messages for user feedback

//////////////////////
//  I M P O R T S   //
//////////////////////

// C U S T O M
import { map } from "../main";

// BEGINS // -->

const toast = (msg) => {
  // clear any toasts if they exist
  if (document.querySelector(".toast-container")) {
    document.querySelectorAll(".toast-container").forEach((t) => t.remove());
  }
  const mapContainer = document.querySelector("#map");
  const toastContainer = document.createElement("div");
  const toastMessage = document.createElement("span");
  toastContainer.className = "toast-container";

  toastMessage.innerHTML = msg;
  toastContainer.append(toastMessage);

  const hashID = Math.floor(100000000 + Math.random() * 900000000);

  toastContainer.id = hashID;
  mapContainer.append(toastContainer);
  toastContainer.classList.add("fade");
  setTimeout(() => {
    toastContainer.remove();
  }, 6000);
};

export default toast;
