//////////////////////
// C O N T R O L S  //
//////////////////////

// Generate the base map controls

//////////////////////
//  I M P O R T S   //
//////////////////////

// O P E N   L A Y E R S
import Zoom from "ol/control/Zoom";
import { FullScreen, ScaleLine } from "ol/control";

// C U S T O M
import toolBar from "./mapToolbar";
import map from "./initMap";
import toast from "./toast";

//  BEGIN  //

// Z O O M
/* const zoomControl = new Zoom({
  target: toolBar,
}); */

// add zoom to toolbar
// map.addControl(zoomControl);

// S C A L E B A R
const scalebar = new ScaleLine({
  bar: true,
  target: "map-scalebar",
  minWidth: 150,
});

map.addControl(scalebar);

/* // M A P   F U L L S C R E E N
const mapFullscreen = new FullScreen({
  target: toolBar,
});

map.addControl(mapFullscreen); */

// allow toggling the scalebar
const scaleBar = document.querySelector("#map-scalebar");
const scaleButton = document.createElement("div");
scaleButton.dataset.tooltip = "Toggle the scalebar";
scaleButton.innerHTML = "SB";
const scaleContainer = document.createElement("div");
scaleContainer.className = "ol-unselectable ol-control";
scaleContainer.appendChild(scaleButton);
scaleButton.addEventListener("click", () => {
  const shown = scaleBar.style.display;
  if (shown === "none") {
    toast("Enabled scalebar");
    scaleBar.style.display = "";
  } else {
    toast("Disabled scalebar");
    scaleBar.style.display = "none";
  }
});
toolBar.appendChild(scaleContainer);
