///////////////////
//  E X P O R T  //
///////////////////

// Export the map as a PNG

//////////////////////
//  I M P O R T S   //
//////////////////////

// C U S T O M
import "../main";
import toast from "./toast";
import toolBar from "./mapToolbar";
import * as htmlToImage from "html-to-image";
import { getDTG, hideOverlays } from "./utils";

// BEGINS // -->

// control to toggle the center coordinate
const exportButton = document.createElement("div");
exportButton.setAttribute("data-tooltip", "Toggle the Center Crosshair widget");
exportButton.innerHTML = "EXP";
const exportContainer = document.createElement("div");
exportContainer.className = "ol-unselectable ol-control";
exportContainer.appendChild(exportButton);
// add it to the toolbar
toolBar.appendChild(exportContainer);

// listen for clicks and export a map image
exportButton.addEventListener("click", () => {
  const map = document.querySelector("#map");
  // hide any overlapping elements
  hideOverlays(true);

  // convert the html to an image
  htmlToImage
    .toPng(map)
    .then((dataUrl) => {
      // create a temp a link to capture the image
      var link = document.createElement("a");
      link.href = dataUrl;
      link.setAttribute("download", `RAPTR-Geo-Export-${getDTG(true)}.png`);
      link.click();
    })
    .then(() => {
      toast("Exported the current map element as an image...");
      // show any overlapping elements
      hideOverlays(false);
    })
    .catch(function (error) {
      console.error("oops, something went wrong!", error);
    });
});
